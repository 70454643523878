import {AboutCompanyType} from "../types";

export const aboutCompany: AboutCompanyType = {
    paragraph1: "Компания «Krep Service» начала свою деятельность в 2012 году и на данный момент является одной из самых успешных стремительно развивающихся молодых компаний в г. Атырау. Основным направлением является продажа широчайшего ассортимента крепежа и метизов, таких как — саморезы, шурупы, винты, дюбели, анкеры, болты, гайки, шайбы, шпильки, такелаж, хомуты, нержавеющий крепеж, перфорация.",
    paragraph2: "На сегодняшний день компания имеет два розничных магазина в г. Атырау по продаже строительного крепежа, и сопутствующих расходных материалов: биты, насадки, сверла, буры и т.д.",
    paragraph3: "За прошедшие годы компания «Krep Service» добилась больших успехов в г. Атырау по продаже метизной продукции. На торговых площадях компании мы предлагаем Вам свыше 1000 наименований различного крепежа.",
    paragraph4: "В компании «Krep Service» Вы можете приобрести метизы как из оцинкованной, так и из нержавеющей стали, мебельный и строительный крепеж, метизную продукцию бытового и промышленного назначения.  Наша компания - продает любой вид крепежа, как мелкими (от 1 шт.), так и крупными партиями. Квалифицированный персонал компании всегда готов прийти на помощь в выборе продукции, индивидуально подойти в решении вопросов клиента. Весь представленный в нашей торговой сети товар, сертифицирован на территории Республики Казахстан и стран СНГ, изготовлен в строгом соответствии со стандартами DIN и ГОСТ.",
    paragraph5: "За годы работы организовано тесное сотрудничество в области снабжения необходимыми материалами со многими предприятиями города и области. На сегодняшний день нашими партнерами и клиентами являются многие крупные строительные организации и предприятия различных форм собственности, и мы по праву гордимся нашим сотрудничеством.",
    paragraph6: "Мы уверены, что сможем помочь Вам, и всегда готовы к взаимовыгодному сотрудничеству!",
};

