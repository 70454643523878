import img1 from '../assets/slider/1.jpg'
import img2 from '../assets/slider/2.jpg'
import img3 from '../assets/slider/3.jpg'
import img4 from '../assets/slider/4.jpg'
import img5 from '../assets/slider/5.jpg'

export const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
];
