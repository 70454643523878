import img1 from '../assets/customers/img1.jpg'
import img2 from '../assets/customers/img2.jpg'
import img3 from '../assets/customers/img3.jpg'
import img4 from '../assets/customers/img4.jpg'
import img5 from '../assets/customers/img5.jpg'
import img6 from '../assets/customers/img6.jpg'
import img7 from '../assets/customers/img7.jpg'
import img8 from '../assets/customers/img8.jpg'
import img9 from '../assets/customers/img9.jpg'
import img10 from '../assets/customers/img10.jpg'
import img11 from '../assets/customers/img11.jpg'
import img12 from '../assets/customers/img12.jpg'
import img13 from '../assets/customers/img13.jpg'
import img14 from '../assets/customers/img14.jpg'
import img15 from '../assets/customers/img15.jpg'
import img16 from '../assets/customers/img16.jpg'
import img17 from '../assets/customers/img17.jpg'
import img18 from '../assets/customers/img18.jpg'


export const customerImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
];
